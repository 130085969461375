<template>
  <div
    class="group/input mb-2 data-[disable-bottom-margin=true]:mb-0 flex flex-col"
    :data-disable-bottom-margin="undefinedIfNotTrue(disableBottomMargin)"
  >
    <div
      class="group flex content-center items-center"
      :data-disabled="undefinedIfNotTrue(isDisabled)"
      :data-invalid="!validationResult.valid"
    >
      <div
        :id="id"
        ref="checkbox"
        :tabindex="isDisabled ? '-1' : tabindex"
        :class="dynamicClasses()"
        data-testid="checkbox_icon_container"
        role="checkbox"
        :aria-checked="checked"
        :aria-labelledby="'label_' + id"
        :aria-required="required || requiredMark"
        :aria-invalid="!validationResult.valid"
        :aria-errormessage="
          !validationResult.valid ? id + '_error_message' : undefined
        "
        @keydown.space.prevent="toggle()"
        @click.stop="toggle()"
        @blur="initializeValidation"
      >
        <div
          class="border-1 flex h-6 w-6 cursor-pointer content-center items-center justify-center rounded border border-base-1 bg-base-2 fill-current object-center p-1 text-center text-sm text-base-2 transition-colors duration-300 group-focus-visible:border-primary-1 group-data-disabled:border-neutral-6 contrast:group-data-disabled:border-base-1 contrast:group-data-disabled:border-dashed group-data-disabled:bg-neutral-8 group-data-disabled:text-neutral-3 group-data-invalid:bg-supporting-19 contrast:group-data-invalid:bg-c-secondary-0 group-data-invalid:text-base-1"
          :class="
            checked
              ? 'bg-primary-1 contrast:bg-base-2 contrast:text-base-1'
              : ''
          "
        >
          <IconPzo
            name="close"
            class="hidden contrast:group-data-invalid:block rounded-full self-center shrink-0 border-2 bg-base-2"
          />
          <span v-show="checked" data-testid="checkbox_icon">
            <IconPzo name="done" class="contrast:group-data-invalid:hidden" />
          </span>
        </div>
      </div>
      <RequiredLabel
        :id="'label_' + id"
        :required="required || requiredMark"
        class="break-word cursor-pointer content-center px-0.5 font-normal sm:px-1"
        @click.stop="toggle()"
        @mousedown.prevent=""
      >
        {{ label }}
      </RequiredLabel>
    </div>
    <InputValidationMessage
      v-if="!validationResult.valid"
      :id="id + '_error_message'"
      :message="validationResult.message"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, ref, type WritableComputedRef } from "vue";
import { undefinedIfNotTrue } from "../composables/attributes";
import { useFieldValidation } from "../composables/useFieldValidation";
import {
  required as requiredValidation,
  withValidators,
} from "../composables/validators";
import type { ModelValidation, FormValidation } from "../types/formValidation";
import InputValidationMessage from "./InputValidationMessage.vue";
import RequiredLabel from "./RequiredLabel.vue";
import { formDisabledKey } from "../types/disabled";
import IconPzo from "./IconPzo.vue";

interface Props {
  id: string;
  label: string;
  modelValue?: boolean;
  tabindex?: string;
  required?: boolean;
  requiredMark?: boolean;
  disabled?: boolean;
  formValidation?: FormValidation;
  disableBottomMargin?: boolean;
  modelValidators?: ModelValidation[];
}

const props = withDefaults(defineProps<Props>(), {
  tabindex: "0",
  formValidation: undefined,
  required: false,
  requiredMark: false,
  disabled: false,
  modelValidators: undefined,
  modelValue: undefined,
});

const formDisabled = inject(formDisabledKey, false);
const isDisabled = computed(() => {
  return formDisabled || props.disabled;
});

const checkbox = ref<HTMLDivElement>();

const emit = defineEmits<{
  (e: "update:modelValue", value?: boolean | undefined): void;
}>();

const checked: WritableComputedRef<boolean | undefined> = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const allValidators = computed(() => {
  if (isDisabled.value) {
    return [];
  }
  return withValidators([], [[requiredValidation, props.required === true]]);
});

const { validationResult, initializeValidation, resetValidation } =
  useFieldValidation(
    props.id,
    checked,
    allValidators,
    props.modelValidators,
    props.formValidation,
  );

function dynamicClasses() {
  let classes = "self-baseline outline-none rounded";
  if (props.tabindex !== "-1") {
    classes +=
      " focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-1";
  }
  return classes;
}

function toggle() {
  if (!isDisabled.value) {
    checked.value = !checked.value;
    focus();
  }
}

function focus() {
  checkbox.value?.focus();
}

defineExpose({ focus, resetValidation });
</script>
